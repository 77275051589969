
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Overview from "@/views/resources/documentation/base/separator/Overview.vue";
import BasicExample from "@/views/resources/documentation/base/separator/BasicExample.vue";
import Sizes from "@/views/resources/documentation/base/separator/Sizes.vue";
import Colors from "@/views/resources/documentation/base/separator/Colors.vue";
import Styles from "@/views/resources/documentation/base/separator/Styles.vue";

export default defineComponent({
  name: "separator",
  components: {
    Overview,
    BasicExample,
    Sizes,
    Colors,
    Styles
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Separator");
    });
  }
});
